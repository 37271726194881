







































































































import Vue from "vue";
import userManager from "@/services/userManager";
import userRoles from "@/constants/userRoles";

export default Vue.extend({
  data() {
    return {
      self: this,
      userRoles,
      mini: true,
      items: [
        {
          title: "QL Quốc gia",
          icon: "fa-solid fa-globe",
          to: "/app/countries",
          condition() {
            return !userManager.checkRole(["telesale"], true);
          },
        },
        {
          title: "QL Thị trường",
          icon: "fas fa-globe-asia",
          to: "/app/markets",
        },
        {
          title: "QL Phòng",
          icon: "mdi-family-tree",
          to: "/app/departments",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "QL Teams",
          icon: "mdi-account-group",
          to: "/app/teams",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "QL Người dùng",
          icon: "mdi-account-circle",
          to: "/app/users",
          condition() {
            return userManager.checkRole(["admin", "kt", "ksnb", "ksnbl"]);
          },
        },
        {
          title: "Chi phí quảng cáo",
          icon: "mdi-advertisements",
          to: "/app/ads",
          condition() {
            return userManager.checkRole(["admin", "kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
        {
          title: "Thanh Toán",
          icon: "mdi-credit-card-outline",
          to: "/app/payment",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "Tổng thống kê quốc gia",
          icon: "mdi-chart-bar-stacked",
          to: "/app/country_statistics/",
          condition() {
            return userManager.checkRole(["kt", "vd", "vdl", "qlk"]);
          },
        },
        {
          title: "Tổng thống kê (Facebook)",
          icon: "mdi-chart-line",
          to: "/app/statistics/general",
          condition() {
            return !userManager.checkRole(["ksnb", "telesale"], true);
          },
        },
        {
          title: "Tổng thống kê (TMDT)",
          icon: "mdi-chart-areaspline-variant",
          to: "/app/statistics/tmdt",
          condition() {
            return !userManager.checkRole(["ksnb", "ksnbl", "telesale"], true);
          },
        },
        {
          title: "Tổng thống kê (Khách sỉ)",
          icon: "mdi-chart-bar",
          to: "/app/statistics/wholesale",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
        {
          title: "Lịch sử hoạt động",
          icon: "mdi-clipboard-text-clock",
          to: "/app/action_logs",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "Cài đặt thưởng ngày",
          icon: "mdi-cash-multiple",
          to: "/app/daily_bonus_settings",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "QL ĐV vận chuyển",
          icon: "mdi-truck-delivery",
          to: "/app/shipping_companies",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "QL ĐV vận chuyển mua hàng",
          icon: "mdi-truck-cargo-container",
          to: "/app/purchase_shipping_companies",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "QL Loại tiền tệ mua hàng",
          icon: "mdi-currency-php",
          to: "/app/purchase_currencies",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "QL Nền tảng MKT",
          icon: "fab fa-facebook-square",
          to: "/app/mkt_platforms",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "QL Phân loại SP",
          icon: "mdi-shape",
          to: "/app/product_categories",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "QL Chuyển kho",
          icon: "mdi-content-save-move",
          to: "/app/warehouse_transfer_commands",
          condition() {
            return userManager.checkRole(["kt", "qlk", "vdl", "vd"]);
          },
        },
        {
          title: "Thay đổi cách bán",
          icon: "mdi-swap-horizontal",
          to: "/app/cost_modify_commands",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
        {
          title: "Chi phí",
          icon: "mdi-cash-100",
          to: "/app/monthly_costs",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "Lương",
          icon: "mdi-account-cash",
          to: "/app/salaries",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "Cài đặt",
          icon: "mdi-cog-outline",
          to: "/app/setting",
          condition() {
            return userManager.checkRole(["admin", "kt"]);
          },
        },
        {
          title: "Đổi mật khẩu",
          icon: "mdi-form-textbox-password",
          to: "/app/change_password",
        },
      ],
      userInfo: {},
    };
  },
  methods: {
    async logout() {
      userManager.deleteAccessToken();
      this.$router.push("/login");
    },
  },
  created() {
    this.userInfo = userManager.getUserInfo();
  },
});
