export default {
    "admin": "Admin",
    "kt": "Kế toán",
    "mkt": "Marketing",
    "vdl": "Vận đơn Lead",
    "vd": "Vận đơn",
    "telesale": "Telesale",
    "cdc": "Check địa chỉ",
    "qlk": "Quản lý kho",
    "design": "Design",
    "content": "Content",
    "rd": "RD",
    "rdl": "RD Lead",
    "ksnb": "KSNB",
    "ksnbl": "KSNB Lead",
    "k": "Khác",
    "tp": "Trưởng phòng",
}
